const Header = function () {
    const body = document.querySelector('body');
    const navbar = document.querySelector('.navbar');
    const takeover = document.querySelector('#nav-takeover')
    const toggle = document.querySelector('#navbar-toggle');
    const icon = document.querySelector('#navbar-icon');
    const close = document.querySelector('#navbar-icon__close');

    toggle.addEventListener('click', function () {
        if (icon.getAttribute('class') == 'navbar-icon d-flex flex-column active') {
            takeover.classList.remove('active');
            icon.classList.remove('active');
            navbar.classList.remove('active');
        } else {
            takeover.classList.add('active');
            icon.classList.add('active');
            navbar.classList.add('active');
        }
    })    

    console.log("workflow successful");
}

export default Header;
